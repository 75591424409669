import { Drawer, DrawerBody, DrawerHeader, DrawerHeaderTitle, makeStyles, tokens } from '@fluentui/react-components';
import { FluentIcon } from '@fluentui/react-icons';
import { PropsWithChildren } from 'react';

const useStyles = makeStyles({
  drawer: {
    width: '560px',
  },
  drawerHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: tokens.spacingHorizontalMNudge,
  },
  headerIcon: {
    marginLeft: tokens.spacingHorizontalMNudge,
  },
  drawerBody: {
    display: 'grid',
    gridTemplateRows: '[input] auto 1fr [buttons] auto',
    paddingBlock: tokens.spacingVerticalXXL,
  },
});

type SelectionDrawerProps = {
  header: string;
  Icon: FluentIcon;
};

export function SelectionDrawer({ header, Icon, children }: PropsWithChildren<SelectionDrawerProps>) {
  const styles = useStyles();

  return (
    <Drawer open={true} position="end" className={styles.drawer}>
      <DrawerHeader className={styles.drawerHeader}>
        <Icon className={styles.headerIcon} />
        <DrawerHeaderTitle>{header}</DrawerHeaderTitle>
      </DrawerHeader>

      <DrawerBody className={styles.drawerBody}>{children}</DrawerBody>
    </Drawer>
  );
}
