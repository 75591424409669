import { Field, MessageBarGroup, makeStyles, mergeClasses, tokens } from '@fluentui/react-components';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetCurrentOrganizationLabel } from '../hooks/useGetCurrentOrganizationLabel';
import { SystemLocation } from './SystemsWizard.Location';
import { PowerLineFrequency } from './SystemsWizard.PowerLineFrequency';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    height: '100%',
  },
  details: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: `${tokens.spacingVerticalS} ${tokens.spacingHorizontalXXXL}`,
    marginLeft: tokens.spacingHorizontalXXXL,
    marginRight: tokens.spacingHorizontalXXXL,
    marginTop: `calc(-1 * ${tokens.spacingVerticalXXL})`,
  },
  key: {
    whiteSpace: 'nowrap',
  },
  value: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  messageBarGroup: {
    alignSelf: 'stretch',
  },
  messageBarGroupNoMessages: {
    display: 'none',
  },
});

type SummaryProps = {
  name: string;
  location: SystemLocation;
  powerLineFrequency: PowerLineFrequency;
  messageBars: ReactElement[];
};
export function Summary({
  name,
  location: { address, description, latitude, longitude },
  powerLineFrequency,
  messageBars,
}: SummaryProps) {
  const styles = useStyles();
  const { t } = useTranslation();
  const organizationLabel = useGetCurrentOrganizationLabel();

  return (
    <div className={styles.container}>
      <div className={styles.details}>
        <div className={styles.key}>{t('systems.organization')}:</div>
        <div className={styles.value}>{organizationLabel}</div>
        <div className={styles.key}>{t('systems.name-of-system')}:</div>
        <div className={styles.value}>
          {name}
          {name.length === 0 && <Field validationState="error" validationMessage={t('common.required')} />}
        </div>
        <div className={styles.key}>{t('systems.address')}:</div>
        <div className={styles.value}>{address}</div>
        <div className={styles.key}>{t('systems.latitude')}:</div>
        <div className={styles.value}>{latitude}</div>
        <div className={styles.key}>{t('systems.longitude')}:</div>
        <div className={styles.value}>{longitude}</div>
        <div className={styles.key}>{t('systems.description')}:</div>
        <div className={styles.value}>{description}</div>
        <div className={styles.key}>{t('systems.power-line-frequency')}:</div>
        <div className={styles.value}>{powerLineFrequency} Hz</div>
      </div>
      <MessageBarGroup
        className={mergeClasses(
          styles.messageBarGroup,
          messageBars.length === 0 ? styles.messageBarGroupNoMessages : undefined
        )}
      >
        {messageBars}
      </MessageBarGroup>
    </div>
  );
}
