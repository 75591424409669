import { TableColumnDefinition, createTableColumn, makeStyles, mergeClasses, tokens } from '@fluentui/react-components';
import { ForwardedRef, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DataList } from './DataList';
import { IndexRange, ListController } from './ListController';

const STICKY_OFFSET = 113;

const useStyles = makeStyles({
  systemName: {
    textAlign: 'left',
    fontSize: tokens.fontFamilyBase,
    fontWeight: tokens.fontWeightRegular,
    padding: 0,
    border: 0,
    color: tokens.colorNeutralForeground1,
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },

  // List controller
  listController: {
    position: 'sticky',
    bottom: '0px',
    zIndex: 1,
    width: '100%',
    backgroundColor: tokens.colorNeutralBackground2,
  },

  listControllerHidden: {
    display: 'none',
  },
});

const PAGING_OPTIONS = [25, 50, 100];

export type System = {
  id: string;
  organizationID: string;
  friendlyName: string;
  description?: string | null;
  address?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  powerlineFrequency: number;
};

type SystemsListProps = {
  className?: string;
  systems: System[];
  columnOptions: string[];
  selectedSystemIds: Set<string>;
  onSelectedSystemIdsChange: (systemIds: Set<string>) => void;
  onSystemSelect: (system: System) => void;
  stuck?: boolean;
  fwdRef?: ForwardedRef<HTMLDivElement>;
};

export function SystemsList({
  className,
  systems,
  columnOptions,
  selectedSystemIds,
  onSelectedSystemIdsChange,
  onSystemSelect,
  stuck,
  fwdRef,
}: SystemsListProps) {
  const { t } = useTranslation();
  const styles = useStyles();

  const [visibleSystems, setVisibleSystems] = useState<System[]>([]);
  const handleRangeChange = useCallback(
    (range: IndexRange) => setVisibleSystems(systems.slice(range.start, range.end)),
    [systems]
  );

  // Columns
  const allColumns: Map<string, TableColumnDefinition<System>> = new Map([
    [
      'name',
      createTableColumn<System>({
        columnId: 'name',
        compare: (a, b) => a.friendlyName.localeCompare(b.friendlyName),
        renderHeaderCell: () => t('common.name'),
        renderCell: (system: System) => (
          <button
            className={styles.systemName}
            onClick={(event) => {
              event.stopPropagation();
              onSystemSelect(system);
            }}
          >
            {system.friendlyName}
          </button>
        ),
      }),
    ],
    // [
    //   'cameras',
    //   createTableColumn<System>({
    //     columnId: 'cameras',
    //     compare: (a, b) => -1,
    //     renderHeaderCell: () => t('cameras.cameras'),
    //     renderCell: (system: System) => {
    //       return 77;
    //     },
    //   }),
    // ],
    [
      'description',
      createTableColumn<System>({
        columnId: 'description',
        compare: (a, b) => (a.description ?? '').localeCompare(b.description ?? ''),
        renderHeaderCell: () => t('systems.description'),
        renderCell: (system: System) => system.description,
      }),
    ],
  ]);

  return (
    <DataList<System>
      className={className}
      fwdRef={fwdRef}
      items={visibleSystems}
      columns={allColumns}
      columnOptions={columnOptions}
      selectedItemIds={selectedSystemIds}
      onSelectedItemIdsChange={onSelectedSystemIdsChange}
      sticky={{ stuck: !!stuck, offset: STICKY_OFFSET }}
    >
      <ListController
        className={mergeClasses(styles.listController, systems.length === 0 ? styles.listControllerHidden : undefined)}
        total={systems.length}
        nrOfRowsOptions={PAGING_OPTIONS}
        onRangeChange={handleRangeChange}
      />
    </DataList>
  );
}
