import {
  Button,
  InputOnChangeData,
  SearchBox,
  SearchBoxChangeEvent,
  Tag,
  makeStyles,
  mergeClasses,
  tokens,
} from '@fluentui/react-components';
import { Delete20Filled } from '@fluentui/react-icons';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Filter, allTrueFilter } from '../util/filter';
import { Option, OptionsDialog } from './ColumnOptionsDialog';

const useStyles = makeStyles({
  container: {
    display: 'grid',
    alignItems: 'center',
    gap: tokens.spacingHorizontalS,
    gridTemplateColumns:
      '[wizard] auto [divider] auto [nrOfItems] auto 1fr [columnOptions] auto [filter] auto [search] auto',
  },

  wizard: {
    gridColumnStart: 'wizard',
  },

  divider: {
    width: '1px',
    height: '16px',
    backgroundColor: tokens.colorNeutralForeground2,
    marginInline: tokens.spacingHorizontalS,
  },

  nrOfItems: {
    gridColumnStart: 'nrOfItems',
  },

  columnOptions: {
    gridColumnStart: 'columnOptions',
  },

  searchBox: {
    gridColumnStart: 'search',
    width: '200px',
  },
});

type ListHeaderProps<T> = {
  className?: string;
  items: T[];
  itemName: string;
  selectedItemIds: Set<string>;
  onDeselectItems: () => void;
  onDeleteItems: () => void;
  createSearchFilter: (searchString: string) => Filter<T>;
  onFilterChange: (filter: Filter<T>) => void;
  columnOptions: Option[];
  defaultColumnOptions: Option[];
  onColumnOptionsChange: (columnOptions: Option[]) => void;
  columnOptionsLabels?: Map<string, string>;
};

export function ListHeader<T>({
  className,
  items,
  itemName,
  selectedItemIds,
  onDeselectItems,
  onDeleteItems,
  createSearchFilter,
  onFilterChange,
  columnOptions,
  defaultColumnOptions,
  onColumnOptionsChange,
  columnOptionsLabels,
  children,
}: PropsWithChildren<ListHeaderProps<T>>) {
  const { t } = useTranslation();
  const styles = useStyles();

  const [filter, setFilter] = useState<Filter<T>>(() => allTrueFilter);
  useEffect(() => onFilterChange(filter), [filter, onFilterChange]);

  function handleSearchOnChange(_: SearchBoxChangeEvent, { value: searchString }: InputOnChangeData) {
    if (searchString.length === 0) {
      setFilter(() => allTrueFilter);
      return;
    }
    setFilter(() => createSearchFilter(searchString));
  }

  return (
    <div className={mergeClasses(styles.container, className)}>
      {selectedItemIds.size === 0 ? (
        /* No item(s) selected */
        <>
          <div className={styles.wizard}>{children}</div>
          <div className={styles.divider}></div>
          <span className={styles.nrOfItems}>{`${items.length} ${itemName}`}</span>
        </>
      ) : (
        /* Item(s) selected */
        <>
          <Tag dismissible dismissIcon={{ 'aria-label': 'remove' }} onClick={onDeselectItems}>
            {`${selectedItemIds.size} ${t('common.selected')}`}
          </Tag>
          <div className={styles.divider}></div>
          <Button icon={<Delete20Filled />} appearance="subtle" onClick={onDeleteItems} />
        </>
      )}

      <OptionsDialog
        className={styles.columnOptions}
        title={t('common.column-options')}
        onSave={onColumnOptionsChange}
        options={columnOptions}
        defaultOptions={defaultColumnOptions}
        labels={columnOptionsLabels}
      />

      <SearchBox
        className={styles.searchBox}
        placeholder={t('common.name')}
        appearance="filled-darker"
        onChange={handleSearchOnChange}
      />
    </div>
  );
}
