import {
  Body1Strong,
  Button,
  Carousel,
  CarouselCard,
  CarouselSlider,
  Dialog,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTrigger,
  Spinner,
  makeStyles,
  tokens,
} from '@fluentui/react-components';
import { PropsWithChildren, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  // DialogWizardStep
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },

  header: {
    marginBottom: tokens.spacingVerticalXXL,
  },

  // DialogWizard
  dialogSurface: {
    maxWidth: '600px',
    height: '600px',
    maxHeight: '500px',
  },
  dialogBody: {
    height: '100%',
    gap: 'initial',
  },
  carousel: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  carouselSlider: {
    flexGrow: 1,
  },
  navContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  navButtons: {
    display: 'flex',
    gap: tokens.spacingHorizontalS,
  },
});

export type DialogWizardStepProps = PropsWithChildren<{ header: string }>;

export function DialogWizardStep({ header, children }: DialogWizardStepProps) {
  const styles = useStyles();

  return (
    <CarouselCard>
      <div className={styles.container}>
        <Body1Strong className={styles.header}>{header}</Body1Strong>
        {children}
      </div>
    </CarouselCard>
  );
}

type DialogWizardProps = {
  buttonText: string;
  defaultOpen: boolean;
  onOpenChange: (open: boolean) => void;
  isInvalid?: (index: number) => boolean;
  stepIsInvalid?: (index: number) => boolean;
  onSubmit: () => Promise<boolean>;
};
export function DialogWizard({
  children,
  buttonText,
  defaultOpen,
  onOpenChange,
  isInvalid = () => false,
  stepIsInvalid = () => false,
  onSubmit,
}: PropsWithChildren<DialogWizardProps>) {
  const styles = useStyles();
  const { t } = useTranslation();

  const [activeIndex, setActiveIndex] = useState<number>(0);

  const numChildren = Array.isArray(children) ? children.length : 0;
  const showPrevious = activeIndex > 0;
  const showNext = activeIndex < numChildren - 1;

  const [clicked, setClicked] = useState(false);
  const icon = clicked ? <Spinner size="tiny" /> : undefined;
  const handleClick = useCallback(async () => {
    setClicked(true);
    const finished = await onSubmit();

    if (!finished) {
      setClicked(false);
      return;
    }
    onOpenChange(false);
  }, [onSubmit, onOpenChange]);

  const handleOpenChange = useCallback(
    (open: boolean) => {
      if (open) {
        // Prevent animation if dialog was opened before
        setActiveIndex(0);
        setClicked(false);
      }
      onOpenChange(open);
    },
    [onOpenChange]
  );

  return (
    <Dialog open={defaultOpen} onOpenChange={(_, { open }) => handleOpenChange(open)}>
      <DialogTrigger>
        <Button appearance="primary">{buttonText}</Button>
      </DialogTrigger>
      <DialogSurface className={styles.dialogSurface}>
        <DialogBody className={styles.dialogBody}>
          <DialogContent>
            <Carousel
              groupSize={1}
              className={styles.carousel}
              activeIndex={activeIndex}
              onActiveIndexChange={(_, data) => setActiveIndex(data.index)}
            >
              {/* Steps 1..n */}
              <CarouselSlider className={styles.carouselSlider}>{children}</CarouselSlider>
              {/* Navigation */}
              <div className={styles.navContainer}>
                <Button appearance="secondary" disabled={!showPrevious} onClick={() => setActiveIndex(activeIndex - 1)}>
                  {t('common.action.previous')}
                </Button>
                <div className={styles.navButtons}>
                  <DialogTrigger action="close">
                    <Button appearance="secondary">{t('common.action.cancel')}</Button>
                  </DialogTrigger>
                  {showNext ? (
                    <Button
                      appearance="primary"
                      disabled={stepIsInvalid(activeIndex)}
                      onClick={() => setActiveIndex(Math.min(activeIndex + 1, numChildren))}
                    >
                      {t('common.action.next')}
                    </Button>
                  ) : (
                    <Button
                      appearance="primary"
                      disabled={clicked || isInvalid(activeIndex)}
                      icon={icon}
                      onClick={() => void handleClick()}
                    >
                      {t('common.action.create')}
                    </Button>
                  )}
                </div>
              </div>
            </Carousel>
          </DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
}
