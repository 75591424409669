import { Body1, Field, Input, makeStyles, tokens } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    height: '100%',
    gap: tokens.spacingVerticalM,
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '380px',
    //marginTop: tokens.spacingVerticalXL,
  },
});

type NameProps = {
  name: string;
  onChange: (name: string) => void;
};
export function Name({ name, onChange }: NameProps) {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Body1>{t('systems.choose-a-clear-name')}</Body1>
      <div className={styles.inputContainer}>
        <Field label={t('systems.system-name')} required>
          <Input value={name} onChange={(_, { value }) => onChange(value)} />
        </Field>
      </div>
    </div>
  );
}
