import { usePageController } from '@axiscommunications/fluent-hooks';
import {
  Body1Strong,
  Menu,
  MenuItemRadio,
  MenuList,
  MenuPopover,
  MenuProps,
  MenuTrigger,
  makeStyles,
  mergeClasses,
  tokens,
} from '@fluentui/react-components';
import { ChevronDownRegular, ChevronLeftRegular, ChevronRightRegular } from '@fluentui/react-icons';
import { t } from 'i18next';
import { useEffect, useState } from 'react';

const useStyles = makeStyles({
  container: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '[showing] auto [rowsPerPage] auto 1fr [pageSelect] auto [navButtons] auto',
  },
  showing: {
    gridColumnStart: 'showing',
    flexShrink: '1',
    marginInline: tokens.spacingHorizontalM,
  },
  rowsPerPageMenuButton: {
    gridColumnStart: 'rowsPerPage',
    display: 'flex',
    alignItems: 'center',
    marginInline: tokens.spacingHorizontalM,
    cursor: 'pointer',

    '&:hover .menuDropdownIcon': {
      color: tokens.colorBrandForeground1,
    },
  },
  selectPageMenu: {
    gridColumnStart: 'pageSelect',
    display: 'flex',
    cursor: 'pointer',

    '&:hover .menuDropdownIcon': {
      color: tokens.colorBrandForeground1,
    },
  },
  navButtons: {
    gridColumnStart: 'navButtons',
    display: 'flex',
  },
  button: {
    marginTop: '9px',
    marginBottom: '9px',
    marginInline: '5px',
    color: tokens.colorNeutralForegroundDisabled,
  },
  canNavigate: {
    cursor: 'pointer',
    color: tokens.colorNeutralForeground2,
    '&:hover': {
      color: tokens.colorBrandForeground1,
    },
  },
});

/**
 * Type for inclusive-exclusive index range
 */
export type IndexRange = { start: number; end: number };

type ListControllerProps = {
  className?: string;
  nrOfRowsOptions: number[];
  total: number;
  onRangeChange: (range: IndexRange) => void;
};

export function ListController({ className, nrOfRowsOptions, total, onRangeChange }: ListControllerProps) {
  const styles = useStyles();

  const [skip, setSkip] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(nrOfRowsOptions[0]);
  const { totalPages, currentPage, pageSize, canGoForward, canGoBackward, nextPage, prevPage, goToPage } =
    usePageController({
      total,
      skip,
      take: rowsPerPage,
      setSkip,
    });

  // Showing
  const firstRowOnPage = 1 + currentPage * rowsPerPage;
  const lastRowOnPage = firstRowOnPage + pageSize - 1;

  // Select nr of rows
  const nrOfRowsOptionsThatFitTotal = nrOfRowsOptions.findIndex((rows) => rows >= total);
  const availableNrOfRowsOptions =
    nrOfRowsOptionsThatFitTotal === -1 ? nrOfRowsOptions : nrOfRowsOptions.slice(0, nrOfRowsOptionsThatFitTotal + 1);
  const [nrOfRowsCheckedValues, setNrOfRowsCheckedValues] = useState<Record<string, string[]>>({
    rowsPerPage: [availableNrOfRowsOptions[0].toString()],
  });
  const nrOfRowsOnChange: MenuProps['onCheckedValueChange'] = (_, { name, checkedItems }) => {
    setNrOfRowsCheckedValues((s) => ({ ...s, [name]: checkedItems }));
    setRowsPerPage(parseInt(checkedItems[0], 10));
  };

  // Select page
  const pageOptions = Array.from({ length: totalPages }, (_, i) => i);
  const [currentPageCheckedValues, setCurrentPageCheckedValues] = useState<Record<string, string[]>>({
    currentPage: [pageOptions[0].toString()],
  });
  const currentPageOnChange: MenuProps['onCheckedValueChange'] = (_, { name, checkedItems }) => {
    setCurrentPageCheckedValues((s) => ({ ...s, [name]: checkedItems }));
    goToPage(parseInt(checkedItems[0], 10));
  };
  // Update checkmark when currentPage is updated outside of menu
  useEffect(() => setCurrentPageCheckedValues((s) => ({ ...s, currentPage: [currentPage.toString()] })), [currentPage]);

  // Update range
  useEffect(() => onRangeChange({ start: skip, end: skip + rowsPerPage }), [skip, rowsPerPage, onRangeChange]);

  return (
    <div className={mergeClasses(styles.container, className)}>
      <div className={styles.showing}>
        {t('list-controller.showing', {
          firstRowOnPage,
          lastRowOnPage,
          total,
        })}
      </div>

      {/* Select nr of rows per page */}
      {availableNrOfRowsOptions.length > 1 && (
        <Menu checkedValues={nrOfRowsCheckedValues} onCheckedValueChange={nrOfRowsOnChange}>
          <MenuTrigger disableButtonEnhancement>
            <div className={styles.rowsPerPageMenuButton}>
              <Body1Strong>{t('list-controller.rows-per-page', { rowsPerPage })}</Body1Strong>
              <ChevronDownRegular className="menuDropdownIcon" fontSize={16} />
            </div>
          </MenuTrigger>

          <MenuPopover>
            <MenuList>
              {availableNrOfRowsOptions.map((option) => (
                <MenuItemRadio
                  key={option.toString()}
                  disabled={option === rowsPerPage}
                  name="rowsPerPage"
                  value={option.toString()}
                >
                  {option}
                </MenuItemRadio>
              ))}
            </MenuList>
          </MenuPopover>
        </Menu>
      )}

      {/* Select page */}
      {pageOptions.length > 1 && (
        <div className={styles.selectPageMenu}>
          <Menu checkedValues={currentPageCheckedValues} onCheckedValueChange={currentPageOnChange}>
            <MenuTrigger disableButtonEnhancement>
              <div className={styles.rowsPerPageMenuButton}>
                <Body1Strong>{t('list-controller.page-of', { currentPage: currentPage + 1, totalPages })}</Body1Strong>
                <ChevronDownRegular className={'menuDropdownIcon'} fontSize={16} />
              </div>
            </MenuTrigger>

            <MenuPopover>
              <MenuList>
                {pageOptions.map((option) => (
                  <MenuItemRadio
                    key={option.toString()}
                    disabled={option === currentPage}
                    name="currentPage"
                    value={option.toString()}
                  >
                    {option + 1}
                  </MenuItemRadio>
                ))}
              </MenuList>
            </MenuPopover>
          </Menu>
        </div>
      )}

      {/* nav buttons */}
      <div className={styles.navButtons}>
        <ChevronLeftRegular
          className={mergeClasses(styles.button, canGoBackward && styles.canNavigate)}
          fontSize={16}
          onClick={prevPage}
        />
        <ChevronRightRegular
          className={mergeClasses(styles.button, canGoForward && styles.canNavigate)}
          fontSize={16}
          onClick={nextPage}
        />
      </div>
    </div>
  );
}
