import {
  BodyWornCamera20Filled,
  BodyWornCamera20Regular,
  Recorder20Filled,
  Recorder20Regular,
} from '@axiscommunications/fluent-icons';
import { useNavigationStyles } from '@axiscommunications/fluent-styles';
import { useWebApplication } from '@axteams-one/bws-cloud-discovery/react';
import { useTrackHeartbeats } from '@axteams-one/bws-cloud-metrics/react';
import { PopulatedTopbar } from '@axteams-one/populated-topbar';
import { makeStyles, mergeClasses, tokens } from '@fluentui/react-components';
import { Settings20Filled, Settings20Regular, bundleIcon } from '@fluentui/react-icons';
import { createContext, useRef } from 'react';
import { Outlet } from 'react-router-dom';

import { BwmcApi } from '../client/GraphQlContext';
import { Sidebar, SidebarItem } from '../components/Sidebar';
import { useRedirectIfNotLoggedIn } from '../hooks/useRedirectIfNotLoggedIn';
import { useScrolled } from '../hooks/useScrolled';
import { CurrentOrganizationProvider } from '../providers/CurrentOrganizationProvider';

const BwcIcon = bundleIcon(BodyWornCamera20Filled, BodyWornCamera20Regular);
const SCUIcon = bundleIcon(Recorder20Filled, Recorder20Regular);
const SettingsIcon = bundleIcon(Settings20Filled, Settings20Regular);

const useStyles = makeStyles({
  page: {
    backgroundColor: tokens.colorNeutralBackground4,
    height: '100vh',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    overflow: 'hidden',
  },
  nav: {
    padding: `${tokens.spacingVerticalXXXL} ${tokens.spacingHorizontalXS} ${tokens.spacingVerticalS}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundColor: tokens.colorNeutralBackground2,
    borderTopLeftRadius: tokens.borderRadiusXLarge,
    boxShadow: tokens.shadow8,
    overflow: 'auto',
    marginTop: tokens.spacingVerticalXS, // To show the top shadow
  },
});

export const ScrolledContext = createContext<boolean>(false);

function App() {
  useTrackHeartbeats();
  useRedirectIfNotLoggedIn();
  const navStyles = useNavigationStyles();

  const styles = useStyles();
  const manual = useWebApplication('bwl-manual');
  const navClass = mergeClasses(navStyles.container, styles.nav);

  const helpArea = {
    about: {
      version: import.meta.env.VITE_APP_VERSION || 'dev build',
      thirdPartyLicencesUrl: `${window.location.origin}/licenses.txt`,
    },
    webManual: { link: manual?.uri },
  };

  const scrollRef = useRef<HTMLDivElement>(null);
  const scrolled = useScrolled(scrollRef);

  return (
    <div className={styles.page}>
      <PopulatedTopbar helpArea={helpArea} />
      <div className={styles.main}>
        <nav className={navClass}>
          <Sidebar>
            <SidebarItem url="cameras" icon={<BwcIcon />} tooltipText="Cameras" />
            <SidebarItem url="systems" icon={<SCUIcon />} tooltipText="Systems" />
          </Sidebar>
          <Sidebar>
            <SidebarItem url="settings" icon={<SettingsIcon />} tooltipText="Settings" />
          </Sidebar>
        </nav>
        <div className={styles.content} ref={scrollRef} id="test">
          <ScrolledContext.Provider value={scrolled}>
            <BwmcApi>
              <CurrentOrganizationProvider>
                <Outlet />
              </CurrentOrganizationProvider>
            </BwmcApi>
          </ScrolledContext.Provider>
        </div>
      </div>
    </div>
  );
}

export { App };
