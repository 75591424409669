import { Body1, Field, Input, Textarea, makeStyles, tokens } from '@fluentui/react-components';
import { FormEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    height: '100%',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '380px',
    marginTop: tokens.spacingVerticalXXL,
    gap: tokens.spacingVerticalM,
  },
  coordinates: {
    display: 'flex',
    gap: tokens.spacingHorizontalS,
    justifyContent: 'stretch',
  },
  latlon: {
    flexShrink: 1,
    flexGrow: 1,
  },
});

export type SystemLocation = {
  address: string;
  latitude: number | undefined;
  longitude: number | undefined;
  description: string;
};

type LocationProps = {
  location: SystemLocation;
  onChange: (location: SystemLocation) => void;
};
export function Location({ location, onChange }: LocationProps) {
  const styles = useStyles();
  const { t } = useTranslation();

  const clampLatLonInput = useCallback(
    (type: string) => (input: FormEvent<HTMLInputElement>) => {
      const absMinMax = type === 'latitude' ? 90 : 180;
      let value = parseFloat(input.currentTarget.value);
      if (value > absMinMax) {
        value = absMinMax;
        input.currentTarget.value = `${absMinMax}`;
      } else if (value < -absMinMax) {
        value = -absMinMax;
        input.currentTarget.value = `-${absMinMax}`;
      }

      if (type === 'latitude') {
        onChange({ ...location, latitude: isNaN(value) ? undefined : value });
      } else {
        onChange({ ...location, longitude: isNaN(value) ? undefined : value });
      }
    },
    [onChange, location]
  );

  return (
    <div className={styles.container}>
      <Body1>{t('systems.set-location-description')}</Body1>
      <div className={styles.inputContainer}>
        <Input
          value={location.address}
          onChange={(_, { value }) => onChange({ ...location, address: value })}
          placeholder={t('systems.address')}
        />
        <div className={styles.coordinates}>
          <Input
            className={styles.latlon}
            type="number"
            placeholder={t('systems.latitude')}
            onInput={clampLatLonInput('latitude')}
          />
          <Input
            className={styles.latlon}
            type="number"
            placeholder={t('systems.longitude')}
            onInput={clampLatLonInput('longitude')}
          />
        </div>
        <Field label={t('systems.description')}>
          <Textarea
            value={location.description}
            rows={4}
            onChange={(_, { value }) => onChange({ ...location, description: value })}
          />
        </Field>
      </div>
    </div>
  );
}
