import { Body1, Label, Radio, RadioGroup, makeStyles, tokens } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    height: '100%',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '380px',
    alignItems: 'left',
    marginTop: tokens.spacingVerticalXXXL,
  },
  label: {
    display: 'block',
  },
});

export type PowerLineFrequency = '50' | '60';

type PowerLineFrequencyProps = {
  frequency: PowerLineFrequency;
  onChange: (frequency: PowerLineFrequency) => void;
};
export function PowerLineFrequency({ frequency, onChange }: PowerLineFrequencyProps) {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Body1>{t('systems.select-the-power-line-frequency')}</Body1>
      <div className={styles.inputContainer}>
        <RadioGroup>
          <Radio
            value="50"
            checked={frequency === '50'}
            label={
              <div>
                50 Hz
                <Label className={styles.label} size="small">
                  {t('systems.used-in-large-parts-of-the-world')}
                </Label>
              </div>
            }
            onChange={(_, { value }) => onChange(value as PowerLineFrequency)}
          />
          <Radio
            value="60"
            checked={frequency === '60'}
            label={
              <div>
                60 Hz
                <Label className={styles.label} size="small">
                  {t('systems.used-in-the-americas-and-parts-of-asia')}
                </Label>
              </div>
            }
            onChange={(_, { value }) => onChange(value as PowerLineFrequency)}
          />
        </RadioGroup>
      </div>
    </div>
  );
}
