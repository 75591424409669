import { Divider, Title2, makeStyles, tokens } from '@fluentui/react-components';

const useStyles = makeStyles({
  header: {
    position: 'sticky',
    top: '0px',
    width: '100%',
    display: 'grid',
    gridTemplateRows: '[padding] 1fr [title] auto [divider] auto',
    zIndex: 97,
    backgroundColor: tokens.colorNeutralBackground2,
    paddingInline: tokens.spacingHorizontalXXXL,
  },

  title: {
    fontFamily: `'LubalinGraphECG-Demi', ${tokens.fontFamilyBase}`,
    gridRowStart: 'title',
    paddingTop: tokens.spacingVerticalXL,
  },

  titleDot: {
    color: tokens.colorNeutralStrokeAccessibleSelected,
  },

  divider: {
    gridRowStart: 'divider',
    marginTop: tokens.spacingVerticalS,
  },
});

type PageHeaderProps = {
  title: string;
};

export function PageHeader({ title }: PageHeaderProps) {
  const styles = useStyles();
  return (
    <div className={styles.header}>
      <Title2 className={styles.title}>
        {title}
        <span className={styles.titleDot}>.</span>
      </Title2>
      <Divider className={styles.divider} />
    </div>
  );
}
