import { RefObject, useEffect, useState } from 'react';

export function useScrolled(divElement: RefObject<HTMLDivElement>): boolean {
  const [scrolled, setScrolled] = useState<boolean>(false);

  useEffect(() => {
    const node = divElement?.current;
    const scrollEventListener = () => {
      if (!node) {
        return;
      }

      if (node.scrollTop > 0 && !scrolled) {
        setScrolled(true);
        return;
      }
      if (node.scrollTop === 0 && scrolled) {
        setScrolled(false);
      }
    };

    if (node) {
      node.addEventListener('scroll', scrollEventListener);
    }

    return () => node?.removeEventListener('scroll', scrollEventListener);
  }, [divElement, scrolled]);

  return scrolled;
}
