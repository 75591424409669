import {
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridProps,
  DataGridRow,
  TableColumnDefinition,
  makeStyles,
  mergeClasses,
  tokens,
} from '@fluentui/react-components';
import { ForwardedRef, PropsWithChildren } from 'react';

const useStyles = makeStyles({
  datagrid: {
    position: 'relative',
    width: '100%',
  },
  datagridHeader: {
    backgroundColor: tokens.colorNeutralBackground2,
    zIndex: 2,
    transition: 'all 700ms',
  },
  datagridHeaderStuck: {
    backgroundColor: tokens.colorNeutralBackground3,
  },
});

export type Sticky = {
  stuck: boolean;
  offset: number;
};

export type Item = { id: string | number };

type DataListProps<T> = {
  className?: string;
  items: T[];
  columns: Map<string, TableColumnDefinition<T>>;
  columnOptions: string[];
  selectedItemIds: Set<string>;
  onSelectedItemIdsChange: (itemIds: Set<string>) => void;
  sticky?: Sticky;
  fwdRef?: ForwardedRef<HTMLDivElement>;
};

export function DataList<T extends Item>({
  className,
  items,
  columns,
  columnOptions,
  selectedItemIds,
  onSelectedItemIdsChange,
  sticky,
  fwdRef,
  children,
}: PropsWithChildren<DataListProps<T>>) {
  const styles = useStyles();

  const stuck = sticky?.stuck ?? false;

  // Selection
  const onSelectionChange: DataGridProps['onSelectionChange'] = (_, data) =>
    onSelectedItemIdsChange(data.selectedItems as Set<string>);

  const visibleColumns: TableColumnDefinition<T>[] = columnOptions.map((columnId: string) => columns.get(columnId)!);

  return (
    <DataGrid
      className={mergeClasses(className, styles.datagrid)}
      ref={fwdRef}
      items={items}
      columns={visibleColumns}
      sortable
      selectionMode="multiselect"
      getRowId={(item: T) => item.id}
      focusMode="composite"
      onSelectionChange={onSelectionChange}
      selectedItems={selectedItemIds}
    >
      <DataGridHeader
        className={mergeClasses(styles.datagridHeader, stuck && styles.datagridHeaderStuck)}
        style={sticky !== undefined ? { position: 'sticky', top: `${sticky.offset ?? 0}px` } : {}}
      >
        <DataGridRow
          selectionCell={{
            checkboxIndicator: { 'aria-label': 'Select all rows' },
          }}
        >
          {({ renderHeaderCell }) => <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>}
        </DataGridRow>
      </DataGridHeader>

      <DataGridBody<T>>
        {({ item, rowId }) => (
          <DataGridRow<T>
            key={rowId}
            selectionCell={{
              checkboxIndicator: { 'aria-label': 'Select row' },
            }}
          >
            {({ renderCell }) => <DataGridCell>{renderCell(item)}</DataGridCell>}
          </DataGridRow>
        )}
      </DataGridBody>

      {children}
    </DataGrid>
  );
}
