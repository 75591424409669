import { NoSitesDark, NoSitesLight, bundleIllustrationSmart } from '@axiscommunications/fluent-illustrations';
import { makeStyles, tokens } from '@fluentui/react-components';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetSystems, useRemoveSystem, useUpdateSystem } from '../client/systems';
import { Option } from '../components/ColumnOptionsDialog';
import { EmptyPage } from '../components/EmptyPage';
import { ListHeader } from '../components/ListHeader';
import { LoadingPage } from '../components/Loading';
import { NoListResult } from '../components/NoListResult';
import { PageHeader } from '../components/PageHeader';
import { System, SystemsList } from '../components/SystemsList';
import { SystemsListSelectionDrawer } from '../components/SystemsListSelectionDrawer';
import { SystemsWizard } from '../components/SystemsWizard';
import { Filter, allTrueFilter } from '../util/filter';
import { ScrolledContext } from './App';

const useStyles = makeStyles({
  page: {
    position: 'relative',
    top: 0,
    width: '100%',
    height: '100%',
    overflowY: 'auto',
  },

  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    flexGrow: '1',
    paddingInline: tokens.spacingHorizontalXXXL,
    paddingBottom: tokens.spacingVerticalXL,
  },

  // List header
  listHeader: {
    width: '100%',
    paddingTop: tokens.spacingVerticalS,
    paddingBottom: tokens.spacingVerticalS,
    position: 'sticky',
    top: '65px',
    zIndex: 3,
    backgroundColor: tokens.colorNeutralBackground2,
  },

  // List
  systemsList: {
    flexGrow: 1,
  },

  emptyList: {
    flexGrow: 0,
  },
});

// const systems: Array<System> = new Array(110).fill(undefined).map((_, index) => ({
//   id: index,
//   friendlyName: 'Testsystem ' + index,
//   description: crypto.randomUUID(),
//   organizationID: 'org_id_3',
//   powerlineFrequency: 57,
//   // cameras: parseInt((Math.random() * 100).toFixed(0), 10),
// }));
// const fetching = false;
// const reexecuteGetSystems = () => {};

const defaultColumnOptions: Option[] = [
  ['name', true],
  //['cameras', true],
  ['description', true],
];

function createSystemSearchFilter(searchString: string): Filter<System> {
  return (system) => !!system.friendlyName.match(searchString);
}

export default function SystemsPage() {
  const { t } = useTranslation();
  const params = useParams();
  const styles = useStyles();

  const [searchFilter, setSearchFilter] = useState<Filter<System>>(() => allTrueFilter);
  const handleSearchFilterChange = useCallback((filter: Filter<System>) => setSearchFilter(() => filter), []);

  const title = t('systems.get-started');
  const description = t('systems.you-need-a-system');
  const navigate = useNavigate();

  const handleOpenChange = useCallback((open: boolean) => navigate(open ? '/systems/create' : '/systems'), [navigate]);

  const { /* error, */ fetching, systems, reexecuteGetSystems } = useGetSystems();
  const updateSystem = useUpdateSystem();
  const removeSystem = useRemoveSystem();
  const [filteredSystems, setFilteredSystems] = useState<System[]>(systems);

  useEffect(() => setFilteredSystems(systems.filter(searchFilter)), [systems, searchFilter]);

  const defaultOpen = params.what === 'create';

  const [selectedSystemIds, setSelectedSystemIds] = useState<Set<string>>(new Set());
  const [selectedSystem, setSelectedSystem] = useState<System>();

  const handleSystemDrawerClose = () => setSelectedSystem(undefined);
  const handleSystemSelect = (system: System) => setSelectedSystem(system);

  const handleDeleteSystems = useCallback(async () => {
    for (const systemId of selectedSystemIds) {
      const system = systems.find(({ id }) => id === systemId);
      if (!system) {
        continue;
      }
      const result = await removeSystem({ id: systemId });
      if (result.error) {
        return false;
      }
    }
    setSelectedSystemIds(new Set());
    reexecuteGetSystems();
    return true;
  }, [removeSystem, selectedSystemIds, reexecuteGetSystems, systems]);

  const [columnOptions, setColumnOptions] = useState<Option[]>(defaultColumnOptions);
  const columnOptionsLabels = new Map([
    ['name', t('common.name')],
    ['description', t('systems.description')],
  ]);

  const scrolled = useContext(ScrolledContext);

  if (systems.length === 0 && fetching) {
    return <LoadingPage />;
  }

  // Wizard
  if (systems.length === 0) {
    return (
      <EmptyPage
        title={title}
        description={description}
        Illustration={bundleIllustrationSmart(NoSitesDark, NoSitesLight)}
      >
        <SystemsWizard defaultOpen={defaultOpen} onOpenChange={handleOpenChange} onAddSystem={reexecuteGetSystems} />
      </EmptyPage>
    );
  }

  // Systems list
  return (
    <>
      <PageHeader title={t('systems.system', { count: 0 })} />

      <div className={styles.container}>
        <ListHeader<System>
          className={styles.listHeader}
          items={systems}
          itemName={t('systems.system', { count: systems.length })}
          selectedItemIds={selectedSystemIds}
          onDeselectItems={() => setSelectedSystemIds(new Set([]))}
          onDeleteItems={() => void handleDeleteSystems()}
          createSearchFilter={createSystemSearchFilter}
          onFilterChange={handleSearchFilterChange}
          columnOptions={columnOptions}
          defaultColumnOptions={defaultColumnOptions}
          onColumnOptionsChange={setColumnOptions}
          columnOptionsLabels={columnOptionsLabels}
        >
          <SystemsWizard defaultOpen={defaultOpen} onOpenChange={handleOpenChange} onAddSystem={reexecuteGetSystems} />
        </ListHeader>

        <SystemsList
          className={filteredSystems.length > 0 ? styles.systemsList : styles.emptyList}
          systems={filteredSystems}
          columnOptions={columnOptions.filter(([_, visible]) => visible).map(([columnId]) => columnId)}
          selectedSystemIds={selectedSystemIds}
          onSelectedSystemIdsChange={setSelectedSystemIds}
          onSystemSelect={handleSystemSelect}
          stuck={scrolled}
        />

        {filteredSystems.length === 0 && <NoListResult />}

        {selectedSystem && (
          <SystemsListSelectionDrawer
            system={selectedSystem}
            onClose={handleSystemDrawerClose}
            onApply={async (updatedSystem: System) => {
              const result = await updateSystem({
                id: updatedSystem.id,
                organizationID: updatedSystem.organizationID,
                resourceParent: updatedSystem.organizationID, // TODO make proper resourceParent
                friendlyName: updatedSystem.friendlyName,
                description: updatedSystem.description,
                address: updatedSystem.address,
                latitude: updatedSystem.latitude,
                longitude: updatedSystem.longitude,
                powerlineFrequency: updatedSystem.powerlineFrequency,
              });

              return !result.error;
            }}
          />
        )}
      </div>
    </>
  );
}
