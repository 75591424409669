import { Recorder20Regular } from '@axiscommunications/fluent-icons';
import {
  Button,
  Field,
  Input,
  Label,
  Radio,
  RadioGroup,
  Textarea,
  makeStyles,
  tokens,
} from '@fluentui/react-components';
import { FormEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectionDrawer } from './SelectionDrawer';
import { System } from './SystemsList';

const useStyles = makeStyles({
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: tokens.spacingVerticalS,
    gridRowStart: 'input',
  },
  coordinates: {
    display: 'flex',
    gap: tokens.spacingHorizontalS,
    justifyContent: 'stretch',
  },
  latlon: {
    flexShrink: 1,
    flexGrow: 1,
  },
  powerLabel: {
    display: 'block',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    gap: tokens.spacingHorizontalS,
    gridRowStart: 'buttons',
  },
});

type SystemsListSelectionDrawerProps = {
  system: System;
  onClose: () => void;
  onApply: (system: System) => Promise<boolean>;
};

export function SystemsListSelectionDrawer({ system, onClose, onApply }: SystemsListSelectionDrawerProps) {
  const { t } = useTranslation();
  const styles = useStyles();

  const [systemCopy, setSystemCopy] = useState<System>({ ...system });
  const [systemIsModified, setSystemIsModified] = useState<boolean>(false);
  const [systemIsInvalid, setSystemIsInvalid] = useState<boolean>(false);

  // Track if system is modified and valid
  useEffect(() => {
    setSystemIsModified(
      system.friendlyName !== systemCopy.friendlyName ||
        system.address !== systemCopy.address ||
        system.latitude !== systemCopy.latitude ||
        system.longitude !== systemCopy.longitude ||
        system.description !== systemCopy.description ||
        system.powerlineFrequency !== systemCopy.powerlineFrequency
    );

    setSystemIsInvalid(systemCopy.friendlyName.length === 0);
  }, [system, systemCopy]);

  // Handle apply
  const [applying, setApplying] = useState<boolean>(false);
  const handleApply = async () => {
    setApplying(true);
    const success = await onApply(systemCopy);
    setApplying(false);
    if (success) {
      onClose();
    }
  };

  // Handle Lat lon input
  const clampLatLonInput = useCallback(
    (type: string) => (input: FormEvent<HTMLInputElement>) => {
      const absMinMax = type === 'latitude' ? 90 : 180;
      let value = parseFloat(input.currentTarget.value);
      if (value > absMinMax) {
        value = absMinMax;
        input.currentTarget.value = `${absMinMax}`;
      } else if (value < -absMinMax) {
        value = -absMinMax;
        input.currentTarget.value = `-${absMinMax}`;
      }

      if (type === 'latitude') {
        setSystemCopy({ ...systemCopy, latitude: isNaN(value) ? undefined : value });
      } else {
        setSystemCopy({ ...systemCopy, longitude: isNaN(value) ? undefined : value });
      }
    },
    [systemCopy]
  );

  return (
    <SelectionDrawer header={system?.friendlyName} Icon={Recorder20Regular}>
      <>
        <div className={styles.inputContainer}>
          {/* Name */}
          <Field label={t('systems.system-name')}>
            <Input
              value={systemCopy.friendlyName}
              onChange={(_, { value }) => setSystemCopy({ ...systemCopy, friendlyName: value })}
            />
          </Field>

          {/* Location */}
          <Field label={t('systems.location')}>
            <Input
              value={systemCopy.address ?? ''}
              onChange={(_, { value }) => setSystemCopy({ ...systemCopy, address: value })}
              placeholder={t('systems.address')}
            />
          </Field>

          {/* Latlon */}
          <div className={styles.coordinates}>
            <Input
              className={styles.latlon}
              type="number"
              value={systemCopy.latitude?.toString() || undefined}
              placeholder={t('systems.latitude')}
              onInput={clampLatLonInput('latitude')}
            />
            <Input
              className={styles.latlon}
              type="number"
              value={systemCopy.longitude?.toString() || undefined}
              placeholder={t('systems.longitude')}
              onInput={clampLatLonInput('longitude')}
            />
          </div>

          {/* Description */}
          <Field label={t('systems.description')}>
            <Textarea
              value={systemCopy.description ?? ''}
              rows={4}
              onChange={(_, { value }) => setSystemCopy({ ...systemCopy, description: value })}
            />
          </Field>

          {/* Power */}
          <Field label={t('systems.power-line-frequency')}>
            <RadioGroup>
              <Radio
                value="50"
                checked={systemCopy.powerlineFrequency.toString() === '50'}
                label={
                  <div>
                    50 Hz
                    <Label className={styles.powerLabel} size="small">
                      {t('systems.used-in-large-parts-of-the-world')}
                    </Label>
                  </div>
                }
                onChange={(_, { value }) => setSystemCopy({ ...systemCopy, powerlineFrequency: parseFloat(value) })}
              />
              <Radio
                value="60"
                checked={systemCopy.powerlineFrequency.toString() === '60'}
                label={
                  <div>
                    60 Hz
                    <Label className={styles.powerLabel} size="small">
                      {t('systems.used-in-the-americas-and-parts-of-asia')}
                    </Label>
                  </div>
                }
                onChange={(_, { value }) => setSystemCopy({ ...systemCopy, powerlineFrequency: parseFloat(value) })}
              />
            </RadioGroup>
          </Field>
        </div>

        <div className={styles.buttonContainer}>
          <Button
            appearance="primary"
            aria-label="Apply"
            onClick={() => void handleApply()}
            disabled={applying || !systemIsModified || systemIsInvalid}
          >
            {t('common.action.apply')}
          </Button>
          <Button appearance="secondary" aria-label="Cancel" onClick={onClose}>
            {t('common.action.cancel')}
          </Button>
        </div>
      </>
    </SelectionDrawer>
  );
}
