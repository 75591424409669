import {
  Button,
  Checkbox,
  Dialog,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTrigger,
  Subtitle1,
  makeStyles,
  tokens,
} from '@fluentui/react-components';
import { ColumnTriple20Regular, ReOrderDotsVertical20Regular } from '@fluentui/react-icons';
import { animated, config, useSprings } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

function move<T>(array: T[], moveIndex: number, toIndex: number) {
  // Copy and remove moved index
  const arrayCopy = [...array];
  const movedItem = arrayCopy[moveIndex];
  arrayCopy.splice(moveIndex, 1);

  // Insert moved item in new place
  arrayCopy.splice(toIndex, 0, movedItem);

  return arrayCopy;
}

const ROW_HEIGHT = 44;

const useStyles = makeStyles({
  dialogSurface: {
    maxWidth: '600px',
    height: '600px',
    maxHeight: '500px',
  },

  dialogBody: {
    height: '100%',
    gap: 'initial',
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: tokens.spacingVerticalM,
    height: '100%',
  },

  content: {
    flexGrow: 1,
  },

  list: {
    paddingInlineStart: '0px',
  },

  listItem: {
    display: 'grid',
    position: 'absolute',
    gridTemplateColumns: '[checkbox] auto [label] auto 1fr [reorder] auto',
    alignItems: 'center',
    listStyle: 'none',
    width: 'inherit',
    height: `${ROW_HEIGHT}px`,
    borderBottom: `1px solid ${tokens.colorNeutralBackground6}`,
    pointerEvents: 'none',
    boxSizing: 'border-box',
    backgroundColor: tokens.colorNeutralBackground1,
    touchAction: 'none',
  },

  listItemCheckbox: {
    gridColumnStart: 'checkbox',
    pointerEvents: 'initial',
  },

  listItemLabel: {
    gridColumnStart: 'label',
    paddingInline: tokens.spacingHorizontalS,
  },

  listItemReorder: {
    gridColumnStart: 'reorder',
    marginInline: tokens.spacingHorizontalM,
    pointerEvents: 'initial',
    cursor: 'pointer',
  },

  buttonContainer: {
    display: 'grid',
    gridTemplateColumns: '[reset] auto 1fr [cancel] auto [save] auto',
    gap: tokens.spacingHorizontalM,
  },

  resetButton: {
    gridColumnStart: 'reset',
  },

  cancelButton: {
    gridColumnStart: 'cancel',
  },

  saveButton: {
    gridColumnStart: 'save',
  },
});

export type Option = [string, boolean];

function springProps(order: number[], immediate = true, active = false, springIndex = 0, editedOptionIndex = 0, y = 0) {
  return function (index: number) {
    if (active && index === springIndex) {
      return {
        y: editedOptionIndex * ROW_HEIGHT + y,
        scale: 1.02,
        zIndex: 1,
        shadow: 15,
        immediate: (key: string) => {
          if (key === 'zIndex') {
            return true;
          } else if (key === 'y') {
            return immediate;
          }
          return false;
        },
        config: (key: string) => (key === 'y' ? config.stiff : config.default),
      };
    }

    return {
      y: order.indexOf(index) * ROW_HEIGHT,
      scale: 1,
      zIndex: 0,
      shadow: 1,
      immediate: immediate,
    };
  };
}

type OptionsDialogProps = {
  className: string;
  title: string;
  options: Option[];
  defaultOptions?: Option[];
  onSave: (editedOptions: Option[]) => void;
  labels?: Map<string, string>;
};

export function OptionsDialog({
  className,
  title,
  options,
  defaultOptions,
  onSave,
  labels = new Map(),
}: OptionsDialogProps) {
  const styles = useStyles();
  const { t } = useTranslation();

  const [editedOptions, setEditedOptions] = useState<Option[]>([]);

  const order = useRef<number[]>([]);
  const [springs, api] = useSprings(editedOptions.length, springProps(order.current));
  const forceUpdateSprings = useCallback(() => void api.start(springProps(order.current, true)), [api]);

  const handleOnOpenChange = useCallback(
    (open: boolean) => {
      if (open) {
        // Copy options so we don't work on original
        setEditedOptions(options.map((option) => [...option]));
        // Default order: 1, 2, ..., length - 1
        order.current = options.map((_, index) => index);
        // Force update
        forceUpdateSprings();
      }
    },
    [forceUpdateSprings, options]
  );

  const handleSave = useCallback(() => {
    // Save according to the order
    onSave(order.current.map((orderIndex) => editedOptions[orderIndex]));
  }, [onSave, editedOptions]);

  const handleReset = useCallback(() => {
    // Reset check
    setEditedOptions((editedOptions) => {
      for (const [defaultKey, defaultValue] of defaultOptions!) {
        const option = editedOptions.find(([key]) => key === defaultKey)!;
        option[1] = defaultValue;
      }
      return [...editedOptions];
    });
    // Reset order
    order.current = defaultOptions!.map(([value]) => editedOptions.findIndex(([v]) => v === value));
    forceUpdateSprings();
  }, [defaultOptions, editedOptions, forceUpdateSprings]);

  const handleChecked = useCallback((key: string, checked: boolean) => {
    setEditedOptions((options) => {
      const option = options.find(([k]) => k === key)!;
      option[1] = checked;
      return [...options];
    });
  }, []);

  const bind = useDrag(({ args: [index], active, movement: [, y], first }) => {
    const springIndex = index as number;

    // Start index of dragged spring
    const startIndex = order.current.indexOf(springIndex);
    // Current index in list of dragged item
    const currentIndex = Math.min(
      Math.max(Math.round((startIndex * ROW_HEIGHT + y) / ROW_HEIGHT), 0),
      options.length - 1
    );

    // Calculate current order
    const newOrder: number[] = move(order.current, startIndex, currentIndex);

    // Update springs
    void api.start(springProps(newOrder, first, active, springIndex, startIndex, y));

    // Save order on drag release
    if (!active) {
      order.current = newOrder;
    }
  });

  return (
    <Dialog onOpenChange={(_, { open }) => handleOnOpenChange(open)}>
      <DialogTrigger>
        <Button className={className} icon={<ColumnTriple20Regular />} appearance="subtle">
          {title}
        </Button>
      </DialogTrigger>
      <DialogSurface className={styles.dialogSurface}>
        <DialogBody className={styles.dialogBody}>
          <DialogContent>
            <div className={styles.container}>
              <Subtitle1>{title}</Subtitle1>
              <div className={styles.content}>
                <div style={{ height: `${ROW_HEIGHT * options.length}px`, width: '550px' }}>
                  {springs.map(({ zIndex, y, scale }, springIndex) => {
                    const [value, checked] = editedOptions[springIndex];
                    return (
                      <animated.div
                        {...bind(springIndex)}
                        className={styles.listItem}
                        key={springIndex}
                        style={{
                          zIndex,
                          y,
                          scale,
                        }}
                        children={
                          <>
                            <Checkbox
                              className={styles.listItemCheckbox}
                              checked={checked}
                              onChange={(_, { checked }) => handleChecked(value, !!checked)}
                              onPointerDown={(event) => event.stopPropagation()}
                            ></Checkbox>
                            <span className={styles.listItemLabel}>
                              {labels.has(value) ? labels.get(value) : value}
                            </span>
                            <ReOrderDotsVertical20Regular className={styles.listItemReorder} />
                          </>
                        }
                      />
                    );
                  })}
                </div>
              </div>
              <div className={styles.buttonContainer}>
                {defaultOptions !== undefined && (
                  <Button className={styles.resetButton} appearance="secondary" onClick={handleReset}>
                    {t('common.action.reset-to-default')}
                  </Button>
                )}
                <DialogTrigger action="close">
                  <Button className={styles.cancelButton} appearance="secondary">
                    {t('common.action.cancel')}
                  </Button>
                </DialogTrigger>
                <DialogTrigger action="close">
                  <Button className={styles.saveButton} appearance="primary" onClick={handleSave}>
                    {t('common.action.save')}
                  </Button>
                </DialogTrigger>
              </div>
            </div>
          </DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
}
