import {
  NoMatchGeneralDark,
  NoMatchGeneralLight,
  bundleIllustrationSmart,
} from '@axiscommunications/fluent-illustrations';
import { Body1, makeStyles, tokens } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  noResultMatching: {
    flexGrow: 1,
    placeSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: tokens.spacingVerticalXXL,
  },
});

export function NoListResult() {
  const styles = useStyles();
  const { t } = useTranslation();

  const NoResultIllustration = bundleIllustrationSmart(NoMatchGeneralDark, NoMatchGeneralLight);

  return (
    <div className={styles.noResultMatching}>
      <NoResultIllustration height={160} />
      <Body1>{t('common.no-result-matching')}</Body1>
    </div>
  );
}
