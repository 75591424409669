import { usePopulatedTopbarValues } from '@axteams-one/populated-topbar';
import { DialogTrigger, MessageBar } from '@fluentui/react-components';
import { ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCreateSystem } from '../client/systems';
import { DialogWizard, DialogWizardStep } from './DialogWizard';
import { Location, SystemLocation } from './SystemsWizard.Location';
import { Name } from './SystemsWizard.Name';
import { PowerLineFrequency } from './SystemsWizard.PowerLineFrequency';
import { Summary } from './SystemsWizard.Summary';

const emptyLocation: SystemLocation = {
  address: '',
  latitude: undefined,
  longitude: undefined,
  description: '',
};

export function SystemsWizard({
  defaultOpen,
  onOpenChange,
  onAddSystem,
}: {
  defaultOpen: boolean;
  onOpenChange: (open: boolean) => void;
  onAddSystem: () => void;
}) {
  const { t } = useTranslation();
  const { organization } = usePopulatedTopbarValues();

  const createSystemText = t('systems.create-system');
  const createSystem = useCreateSystem();

  const [name, setName] = useState('');
  const [location, setLocation] = useState<SystemLocation>(emptyLocation);
  const [powerLineFrequency, setPowerLineFrequency] = useState<PowerLineFrequency>('50');
  const [messageBars, setMessageBars] = useState<ReactElement[]>([]);

  const resetState = useCallback(() => {
    setName('');
    setLocation(emptyLocation);
    setPowerLineFrequency('50');
    setMessageBars([]);
  }, []);

  const handleOpenChange = useCallback(
    (open: boolean) => {
      if (open) {
        resetState();
      }
      onOpenChange(open);
    },
    [onOpenChange, resetState]
  );
  const handleSubmit = useCallback(async () => {
    const options = {
      organizationID: organization!.id,
      resourceParent: organization!.id, // TODO make proper resourceParent
      friendlyName: name,
      powerlineFrequency: parseInt(powerLineFrequency),
      ...location,
    };
    setMessageBars([]);
    const { error } = await createSystem(options);
    onAddSystem();
    if (error) {
      setMessageBars([<MessageBar intent="error">{t('systems.create-system-failed')}</MessageBar>]);
    }
    return !error;
  }, [name, location, powerLineFrequency, organization, createSystem, onAddSystem, t]);

  return (
    <DialogWizard
      buttonText={createSystemText}
      defaultOpen={defaultOpen}
      onOpenChange={handleOpenChange}
      onSubmit={handleSubmit}
      stepIsInvalid={() => name === ''}
    >
      <DialogWizardStep header={t('systems.name-system')}>
        <Name name={name} onChange={setName} />
      </DialogWizardStep>
      <DialogWizardStep header={t('systems.describe-your-system')}>
        <Location location={location} onChange={setLocation} />
      </DialogWizardStep>
      <DialogWizardStep header={t('systems.power-line-frequency')}>
        <PowerLineFrequency frequency={powerLineFrequency} onChange={setPowerLineFrequency} />
      </DialogWizardStep>
      <DialogWizardStep header={t('systems.summary')}>
        <DialogTrigger action="close">
          <Summary name={name} location={location} powerLineFrequency={powerLineFrequency} messageBars={messageBars} />
        </DialogTrigger>
      </DialogWizardStep>
    </DialogWizard>
  );
}
