import {
  CreateSystemInput,
  RemoveSystemInput,
  System,
  UpdateSystemInput,
  useCreateSystemMutation,
  useGetSystemsQuery,
  useRemoveSystemMutation,
  useUpdateSystemMutation,
} from '../graphql/__generated__/graphql';
import { useCurrentOrganization } from '../providers/CurrentOrganizationProvider';

export function useCreateSystem() {
  const [result, executeMutation] = useCreateSystemMutation();
  if (result.error) {
    throw result.error;
  }
  return (options: CreateSystemInput) => executeMutation({ input: options });
}

export function useUpdateSystem() {
  const [result, executeMutation] = useUpdateSystemMutation();
  if (result.error) {
    throw result.error;
  }
  return (options: UpdateSystemInput) => executeMutation({ input: options });
}

export function useRemoveSystem() {
  const [result, executeMutation] = useRemoveSystemMutation();
  if (result.error) {
    throw result.error;
  }
  return (options: RemoveSystemInput) => executeMutation({ input: options });
}

const noSystems: System[] = [];

export function useGetSystems() {
  const { id: organizationId } = useCurrentOrganization();

  const [result, reexecuteGetSystems] = useGetSystemsQuery({
    requestPolicy: 'cache-and-network',
    variables: { options: { organizationId } },
  });

  const error = !!result.error;
  const fetching = result.fetching || result.stale;
  const systems = result.data?.getSystems ?? noSystems;

  return { fetching, error, systems, reexecuteGetSystems };
}
